import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Card, Col, Input, Row, Space } from "antd";
import { InputAreaLabel, InputLabel, InputPassword } from "../../components/forms/input";
import { TextXSMall, TextSmall } from "../../components/forms/text";
import { SelectDropdown, TreeSelectDropdown } from '../../components/forms/dropdown.js'
import { DatePickerForm } from "../../components/forms/datePicker";
import { CheckboxTheme } from "../../components/forms/checkbox";
const RenderForm = ({ formList, setValue, getValues, errors, control, renderButton, styleRow, ...rest }) => {
	const stuff = {
		control,
		errors,
		getValues,
		setValue,
	}
	return (
		<Row gutter={[16, 16]} style={styleRow}>
			{
				formList.map(f => {
					return (
						<Col xs={{ span: 24 }} md={{ span: f.span }} xl={{ span: f.span }} lg={{ span: f.span }} style={{ ...f.style }}>
							{renderRequiredStyle(f.rules, f.label)}
							{renderInputType(f, stuff)}
							{renderTextError(f.label, f.name, errors)}
						</Col>
					)
				})
			}
			{renderButton}
		</Row>
	)
}

const renderTextError = (label, name, errors) => {
	return (
		errors[name] ?
			<TextXSMall color={'red'} text={renderTypeError(label, name, errors)} /> : ''
	)
}

const renderTypeError = (label, name, errors) => {
	switch (errors[name].type) {
		case 'required':
			return (`โปรดระบุ${label}`)
		case 'pattern':
			return errors[name].message ? errors[name].message : `รูปแบบ${label}ไม่ถูกต้อง`
		case 'maxLength':
			return (`ระบุไม่เกิน ${errors[name].message} ตัวอักษร`)
		case 'minLength':
			return (`ระบุไม่น้อยกว่า ${errors[name].message} ตัวอักษร`)
	}

}

const renderRequiredStyle = (rules, label) => {
	return (
		<div style={{ display: 'flex' }}>
			{
				!!rules && rules.required ? <TextSmall text={`*`} color={'red'} /> : ''
			}
			<TextSmall text={label} />
		</div>
	)
}

const renderInputType = (e, stuff) => {
	switch (e.type) {
		case 'INPUT':
			return (
				<InputLabel
					item={e}
					{...stuff}
				/>
			)
		case 'INPUT_AREA':
			return (
				<InputAreaLabel
					item={e}
					{...stuff}
				/>
			)
		case 'INPUT_PASSWORD':
			return (
				<InputPassword
					item={e}
					{...stuff}
				/>
			)
		case 'SELECT':
			return (<SelectDropdown item={e}
				{...stuff} />)
		case 'TREE_SELECT':
			return (<TreeSelectDropdown item={e}
				{...stuff} />)

		case 'DATE_PICKER':
			return (<DatePickerForm item={e}
				{...stuff} />)
		case 'CHECKBOX':
			return (<CheckboxTheme item={e}
				{...stuff} />)

		// case 'MULTIPLE_SELECT':
		// 	return (<p>MULTIPLE_SELECT</p>)
	}
}

export default RenderForm